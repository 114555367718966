<template>
	<div>
		<!-- 首页固定区 -->
		<div class="index-wrap" :style="{ background: backgroundColor }" style="display: none;">
			<div class="index">
				<div class="banner">
					<el-carousel height="350px" arrow="hover" v-loading="loadingAd" @change="handleChange">
						<el-carousel-item v-for="item in adList" :key="item.adv_id">
							<el-image :src="$img(item.adv_image)" fit="cover"
								@click="$router.pushToTab(item.adv_url.url)" />
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>


		<!--右侧新闻列表-->
		<div class="index-wrap" style="position: absolute;margin-top: 10px;margin-bottom: 0px;">
			<div class="index" style="padding-right: 0px;padding-left: 960px;">
				<div class="banner" style="height: 420px;overflow: hidden;">

					<div class="box_0">
						<div class="title">
							<el-row>
								<el-col :span="3">
									<i class="el-icon-message" style="font-size: 20px;color: #3366FF;"></i>
								</el-col>
								<el-col :span="15">
									<div @click="$router.pushToTab({ path: '/cms/help/listother-3'})">
										业务介绍
									</div>

								</el-col>
								<el-col :span="6">
									<div @click="$router.pushToTab({ path: '/cms/help/listother-3'})">
										更多业务
									</div>

								</el-col>
							</el-row>
						</div>

						<div class="list">
							<li v-for="(item_home_0, index_home_0) in homeinfodataArr.yewu_data" :key="index_home_0"
								@click="$router.pushToTab({ path: '/cms/help-' + item_home_0.id })">
								{{index_home_0+1}}.{{item_home_0.title}}
							</li>
						</div>
					</div>


					<div class="box_1">
						<div class="title">
							<el-row>
								<el-col :span="3">
									<i class="el-icon-warning-outline" style="font-size: 20px;color: #3366FF;"></i>
								</el-col>
								<el-col :span="15">
									<div @click="$router.pushToTab({ path: '/cms/help/listother-2'})">
										常见问题
									</div>
								</el-col>
								<el-col :span="6">

									<div @click="$router.pushToTab({ path: '/cms/help/listother-2'})">
										更多问题
									</div>
								</el-col>
							</el-row>
						</div>

						<div class="list">
							<li v-for="(item_home_1, index_home_1) in homeinfodataArr.yewu_problem" :key="index_home_1"
								@click="$router.pushToTab({ path: '/cms/help-' + item_home_1.id })">
								{{index_home_1+1}}.{{item_home_1.title}}
							</li>
						</div>
					</div>

				</div>
			</div>
		</div>


		<!--最新成交-->
		<div class="index-wrap" style="height: 70px;margin-top: 10px;margin-bottom: 0px;" >
			<div class="index">
				<div class="banner"
					style="background-color: #fff;height: 60px;line-height: 60px;overflow: hidden;border-radius: 10px;">
					<el-row>
						<el-col :span="3">
							<div class="chengjiao_title">最新成交</div>
						</el-col>
						<el-col :span="3">
							<div class="chengjiao_game_name" style="width: 100px;">
								{{homeinfodataArr.new_order.game_name}}
							</div>
						</el-col>
						<el-col :span="14">
							<div class="chengjiao_game_info_content">
								{{homeinfodataArr.new_order.order_name}}
							</div>
						</el-col>
						<el-col :span="4">
							<div class="chengjiao_game_price_title">成交价:<span
									style="margin-left: 3px;color: #ff547b;">¥</span><span
									class="price">{{homeinfodataArr.new_order.order_money}}</span>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>


		<!--游戏分类-->
		<div class="game_list_wrap">
			<div class="content"
				style="margin: 0 auto;padding-right: 260px;box-sizing: border-box;display: flex;flex-direction: row;justify-content: space-between;background-color: transparent;">
				<div style="height: 350px;width: 100%;background-color: #fff;border-radius: 10px;">
					<!-- 使用tabs来区分 分类 -->
					<el-row class="title">
						<el-col :span="21">
							<el-tabs v-model="activeName" @tab-click="fenleiChange">
								<el-tab-pane label="热门游戏" name="gametab_999"></el-tab-pane>
								<el-tab-pane :label="item.category_name" :name="'gametab_'+index"
									v-for="(item, index) in goodsCategoryTree" :key="index">
								</el-tab-pane>
								<!-- <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane> -->
								<!-- <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane> -->
							</el-tabs>
						</el-col>
						<el-col :span="3">
							<div class="more_info" @click="goToMoreGame()">
								更多游戏<i class="el-icon-arrow-right"></i>
							</div>
						</el-col>
					</el-row>


					<div v-if="fenlei_index==0" class="content_list">
						<div @click="goToGoodsListPage(item_0)" class="right"
							v-for="(item_0, index_0) in goodsCategoryAll" :key="index_0">
							<el-image :src="$img(item_0.image)"></el-image>
							<p>{{item_0.category_name}}</p>
						</div>
					</div>

					<div class="content_list">
						<div :name="'gametab_'+index"
							v-for="(item, index) in goodsCategoryTree" :key="index" v-if="fenlei_index==(index+1)">
							<div @click="goToGoodsListPage(item_1)" class="right"
								v-for="(item_1, index_1) in item.child_list" :key="index_1">
								<el-image :src="$img(item_1.image)"></el-image>
								<p>{{item_1.category_name}}</p>
							</div>
						</div>
					</div>


				</div>

			</div>
		</div>




		<!--商品列表-->
		<div class="goods_list_wrap">
			<div class="content">
				<!-- 使用tabs来区分 分类 -->
				<el-row class="title">

					<el-col :span="2">
						<div
							style="font-size: 22px;font-weight: bold;color: #333;margin-top: 5px;">
							顶级账号</div>
					</el-col>
					<el-col :span="20">
						<el-tabs v-model="activeGoodsName" @tab-click="handleClick">
							<el-tab-pane :label="item.category_name" :name="'goodstab_'+index"
								v-for="(item, index) in goodsHomeListArr" :key="index">
							</el-tab-pane>
						</el-tabs>
					</el-col>
					<el-col :span="2">
						<div class="more_info" @click="goToMoreGame()">
							更多账号<i class="el-icon-arrow-right"></i>
						</div>
					</el-col>
				</el-row>
				
				<div class="content_list" style="padding-left: 20px;padding-right: 20px;">
					<div v-if="zhanghao_fenlei_index==index" v-for="(item, index) in goodsHomeListArr" :key="index">
						<div @click="goToGoodsListPage(item_1)" class="right"
							v-for="(item_1, index_1) in item.goods_list" :key="index_1">
							<div class="seckill-goods" @click="go(item_1)">
								<div style="height: 160px;padding-top: 10px;" class="seckill-goods-img">
									<img :src="$img(item_1.goods_image)" @error="imageError(index)" />
								</div>
								<p>{{ item_1.goods_name }}</p>
								<div class="seckill-price-wrap">
									<p class="ns-text-color">
										￥
										<span>{{ item_1.price }}</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>



		<div class="content">
			<!-- 领券中心 -->
			<div class="content-div" v-if="addonIsExit.coupon == 1 && couponList.length > 0">
				<div class="coupon">
					<div class="coupon-title">
						<p class="coupon-font">领券中心</p>
						<p class="coupon-en">coupon</p>
						<p class="coupon-more" @click="$router.push('/coupon')">
							<span>更多</span>
							<i class="iconfont iconarrow-right"></i>
						</p>
					</div>
					<ul class="coupon-list">
						<li v-for="(item, index) in couponList" :key="index">
							<p class="coupon-price ns-text-color" v-if="item.type == 'reward'"
								:class="{ disabled: item.useState == 2 }">
								￥
								<span>{{parseInt(item.money) }}</span>
							</p>
							<p class="coupon-price ns-text-color" v-else-if="item.type == 'discount'"
								:class="{ disabled: item.useState == 2 }">

								<span>{{ parseFloat(item.discount) }}</span>折
							</p>
							<p class="coupon-term" v-if="item.at_least > 0" :class="{ disabled: item.useState == 2 }">
								满{{ item.at_least }}可用</p>
							<p class="coupon-term" v-else :class="{ disabled: item.useState == 2 }">
								满{{ item.at_least }}可用</p>
							<p class="coupon-receive ns-text-color" @click="couponTap(item, index)">
								<span v-if="item.useState == 0">立即领取</span>
								<span v-else>去使用</span>
								<i class="iconfont iconarrow-right"></i>
							</p>
						</li>
					</ul>
				</div>
			</div>

			<!-- 广告 -->
			<div class="content-div" v-if="adLeftList.length > 0 || adRightList.length > 0">
				<div class="ad-wrap">
					<div class="ad-big">
						<div class="ad-big-img" v-for="(item, index) in adLeftList" :key="index">
							<el-image :src="$img(item.adv_image)" fit="cover" @error="adLeftImageError(index)"
								@click="$router.pushToTab(item.adv_url.url)"></el-image>
						</div>
					</div>
					<div class="ad-small">
						<div class="ad-small-img" v-for="(item, index) in adRightList" :key="index">
							<el-image :src="$img(item.adv_image)" fit="cover" @error="adRightImageError(index)"
								@click="$router.pushToTab(item.adv_url.url)"></el-image>
						</div>
					</div>
				</div>
			</div>

			<!-- 限时秒杀 -->
			<div class="content-div" v-if="addonIsExit.seckill == 1 && listData.length > 0">
				<div class="seckill-wrap">
					<div class="seckill-time">
						<div class="seckill-time-left">
							<i class="iconfont iconmiaosha1 ns-text-color"></i>
							<span class="seckill-time-title ns-text-color">限时秒杀</span>
							<span>{{ seckillText }}</span>
							<count-down class="count-down" v-on:start_callback="countDownS_cb()"
								v-on:end_callback="countDownE_cb()" :currentTime="seckillTimeMachine.currentTime"
								:startTime="seckillTimeMachine.startTime" :endTime="seckillTimeMachine.endTime"
								:dayTxt="'：'" :hourTxt="'：'" :minutesTxt="'：'" :secondsTxt="''"></count-down>
						</div>
						<div class="seckill-time-right" @click="$router.push('/promotion/seckill')">
							<span>更多商品</span>
							<i class="iconfont iconarrow-right"></i>
						</div>
					</div>
					<div class="seckill-content">
						<vue-seamless-scroll :data="listData" :class-option="optionLeft" class="seamless-warp2">
							<ul class="item" :style="{ width: 250 * listData.length + 'px' }">
								<li v-for="(item, index) in listData" :key="index">
									<div class="seckill-goods"
										@click="$router.pushToTab('/promotion/seckill-' + item.id)">
										<div class="seckill-goods-img"><img :src="$img(item.sku_image, { size: 'mid' })"
												@error="imageError(index)" /></div>
										<p>{{ item.sku_name }}</p>
										<div class="seckill-price-wrap">
											<p class="ns-text-color">
												￥
												<span>{{ item.seckill_price }}</span>
											</p>
											<p class="primary-price">￥{{ item.price }}</p>
										</div>
									</div>
								</li>
							</ul>
						</vue-seamless-scroll>
					</div>
				</div>
			</div>

			<!-- 楼层区 -->
			<div class="content-div">
				<div class="floor">
					<div v-for="(item, index) in floorList" :key="index" class="floor_item">
						<floor-style-1 v-if="item.block_name == 'floor-style-1'" :data="item" />
						<floor-style-2 v-if="item.block_name == 'floor-style-2'" :data="item" />
						<floor-style-3 v-if="item.block_name == 'floor-style-3'" :data="item" />
					</div>
				</div>
			</div>

			<!-- 浮层区 -->
			<div class="floatLayer-wrap" v-if="floatLayer.is_show_type">
				<div class="floatLayer">
					<div class="img-wrap">
						<img :src="$img(floatLayer.img_url)" @click="$router.pushToTab(floatLayer.link.url)" />
					</div>
					<i class="el-icon-circle-close" @click="closeFloat"></i>
				</div>
			</div>

			<!-- 悬浮搜索 -->
			<div class="fixed-box" :style="{ display: isShow ? 'block' : 'none' }">
				<div class="header-search">
					<el-row>
						<el-col :span="6">
							<router-link to="/" class="logo-wrap"><img :src="$img(siteInfo.logo)" /></router-link>
						</el-col>
						<el-col :span="13">
							<div class="in-sousuo">
								<div class="sousuo-box">
									<el-dropdown @command="handleCommand" trigger="click">
										<span class="el-dropdown-link">
											{{ searchTypeText }}
											<i class="el-icon-arrow-down"></i>
										</span>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item command="goods">商品</el-dropdown-item>
											<el-dropdown-item command="shop">店铺</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
									<input type="text" :placeholder="defaultSearchWords" v-model="keyword"
										@keyup.enter="search" maxlength="50" />
									<el-button type="primary" size="small" @click="search">搜索</el-button>
								</div>
							</div>
						</el-col>
						<el-col :span="5">
							<div class="cart-wrap">
								<router-link class="cart" to="/cart">
									<span>我的购物车</span>
									<el-badge v-if="cartCount" :value="cartCount" type="primary"><i
											class="iconfont icongouwuche"></i></el-badge>
									<i v-else class="iconfont icongouwuche"></i>
								</router-link>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import floorStyle1 from './components/floor-style-1.vue';
	import floorStyle2 from './components/floor-style-2.vue';
	import floorStyle3 from './components/floor-style-3.vue';
	import index from './_index/index.js';

	export default {
		name: 'index',
		components: {
			floorStyle1,
			floorStyle2,
			floorStyle3
		},
		mixins: [index]
	};
</script>

<style lang="scss" scoped>
	@import './_index/index.scss';
</style>

<style lang="scss">
	.count-down {
		span {
			display: inline-block;
			width: 22px;
			height: 22px;
			line-height: 22px;
			text-align: center;
			background: #383838;
			color: #ffffff;
			border-radius: 2px;
		}
	}

	.more_info {
		color: $base-color-info;
		width: 100%;
		text-align: right;
		height: 50px;
		line-height: 50px;
		font-size: 16px;
		cursor: pointer;
	}

	.el-tabs {
		.el-tabs__header {
			.el-tabs__nav-wrap {
				.el-tabs__nav-scroll {
					.el-tabs__nav {
						.el-tabs__active-bar {
							display: none;
						}

						.el-tabs__item {
							width: 120px;
							height: 50px;
							padding: 0;
							text-align: center;
							line-height: 50px;
							font-size: 18px;
							color: $base-color-info;
							// border-radius: 16px;
						}

						.el-tabs__item.is-active {
							font-size: 18px;
							color: $base-color;
							border-bottom: 3px solid $base-color;
							// color: #ffffff;
							// background: linear-gradient(45deg, $base-color 0%, #F47039 99%);
						}
					}
				}
			}
		}

		.el-tabs__nav-wrap::after {
			display: none;
		}
	}
</style>
